import logo from './logomer.png';
import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import {useState,useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMobileScreenButton, faMapLocation, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function App() {
  const url = 'https://data-asg.goldprice.org/dbXRates/USD';
  const [price, setPrice] = useState(null)
  
  useEffect(() => {
    axios.get(url)
    .then(response => {
      setPrice(response.data)
    })
  }, [url])

  if(price){

    let gprice      = (price.items[0].xauPrice);

    let oneforseven = 1.417;
    let diveightk   = 155.5;
    let divtwonek   = 132.4;
    let divtwtwo    = 127.332;
    let divtwfourk  = 116.64;

    let twek = gprice * oneforseven / diveightk ;
    let twok = gprice * oneforseven / divtwonek ;
    let twtk = gprice * oneforseven / divtwtwo ;
    let twfk = gprice * oneforseven / divtwfourk ;

    
    return (
      
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
  
          <div className='flex-container1'>
  
          <Box className='boxg' borderRadius="2%"
            sx={{

              
            }}>
            <p>
              <text>Gold 1 ounce / USD</text>
              <br/>
              <text>{((price.items[0].xauPrice)).toFixed(3)}</text>
            </p>
          </Box>
          </div>

          <p id='none'>
            Live Prices
          </p>
  
          <div className='flex-container2'>
  
          <Box className='boxg1' borderRadius="2%"
            sx={{
          
              
            }}>
            <h4>
            <text>24K / per gram</text>
            <br/>
            <text>BD {twfk.toFixed(3)}</text>
            </h4>
          </Box>
  
          <Box className='boxg1' borderRadius="2%"
            sx={{
           
              
            }}>
            <h4>
            <text>22K / per gram</text>
            <br/>
            <text>BD {twtk.toFixed(3)}</text>
            </h4>
          </Box>
  
          <Box className='boxg1' borderRadius="2%"
            sx={{
             
              
            }}>
            <h4>
            <text>21K / per gram</text>
            
            <br/>
            <text>BD {twok.toFixed(3)}</text>
            </h4>
          </Box>
  
          <Box className='boxg1' borderRadius="2%"
            sx={{
             
              
            }}>
            <h4>
            <text>18K / per gram</text>
            <br/>
            <text>BD {twek.toFixed(3)}</text>
            </h4>
          </Box>
          </div>

          <div>
            <br/>
            <tr>
              <td>
              
              <a href="tel:+97333902929"><FontAwesomeIcon icon={faMobileScreenButton}></FontAwesomeIcon> +973 33902929</a>
              </td>
              
              <td><a href="https://www.instagram.com/almerriyah_jewellery/?utm_medium=copy_link%22%3E%7B%27Insta%27%7D"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon> almerriyah_jewellery</a></td>
              
            </tr>

            <tr>
              <td><a href="https://www.google.com/maps/place/Almerriyah+Jewellery/@26.2196941,50.5376297,17z/data=!3m1!4b1!4m5!3m4!1s0x3e49af4d3e684d33:0x22e38ef600c6ef0!8m2!3d26.2196946!4d50.5376297?hl=en-BH%22%3E%7B%27Google Maps'}"><FontAwesomeIcon icon={faMapLocation} /> Google Maps</a></td>
              <td><a href="https://api.whatsapp.com/message/OJXZZ37QIORZE1?autoload=1&app_absent=0"><FontAwesomeIcon icon={faWhatsapp} /> +973 33902929</a></td>
            </tr>
            
            <tr>
            <td colSpan={2}><a href="mailto:almerriyahjew@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> Apply Here!</a></td>
            </tr>

            {/*
            <tr>
            <td className='shop'  colSpan={2} ><a className='shopc' href="https://merriyahstore.gsabh14.com/web"><FontAwesomeIcon icon={faCartShopping} /> Shop Here</a></td>
            </tr>
            */}

          </div>
          <div>
            <p></p>
          </div>

        </header>
      </div>
      
    );
  }
}
export default App;